import {Routes} from '@angular/router';
import {LOGIN_REDIRECT, NOT_FOUND_REDIRECT} from '@common/globals/routes';

export const APP_ROUTES: Routes = [
  ...LOGIN_REDIRECT,
  {
    path: 'login',
    loadChildren: () => import('../main-pages/login-check/login-check.routes'),
  },
  {
    path: 'pdf/download',
    loadChildren: () => import('../main-pages/pdf-download/pdf-download.routes'),
  },
  {
    path: 'dashboard',
    loadChildren: () => import('../main-pages/dashboard/dashboard.routes'),
  },
  {
    path: 'checkout',
    loadChildren: () => import('../main-pages/checkout/checkout.routes'),
  },
  {
    path: 'trades',
    loadChildren: () => import('../main-pages/trades/trades.routes'),
  },
  {
    path: 'trade/details/:id',
    loadChildren: () => import('../main-pages/trade-details/trade-details.routes'),
  },
  {
    path: 'reports/:reportName',
    loadChildren: () => import('../main-pages/reports/reports.routes'),
  },
  {
    path: 'scanproducts',
    loadChildren: () => import('../main-pages/scan-products/scan-products.routes'),
  },
  ...NOT_FOUND_REDIRECT,
];
