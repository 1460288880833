import {Injectable} from '@angular/core';
import {LocalStorageModuleTypes} from '@common/enum/localStorageModuleTypes';
import {ModuleTypes} from '@common/enum/moduleTypes.enum';
import {Roles} from '@common/enum/roles.enum';
import {EnvUrl} from '@common/statics/getEnvURL';
import {getLoginData} from '@common/statics/getLoginData';
import {isAccountOwner} from '@common/statics/isAccountOwner';
import {AppModules} from '@common/statics/modules';
import {UserRoles} from '@common/statics/userRoles';
import {AuthCommon} from '@common/ts/authCommon';
import {TradeURLs} from '@common/ts/tradeInterfaces';
import {MenuRoutesService} from '@trade/services/menu-routes/menu-routes.service';
import {UserDataService} from '@trade/services/user-data/user-data.service';
import {Observable, of, switchMap} from 'rxjs';

@Injectable()
export class AuthService extends AuthCommon<TradeURLs, Roles> {

  constructor(public routerSrv: MenuRoutesService,
              public override userDataService: UserDataService) {
    super(userDataService);
  }

  override authHandler(roles: Roles[]): Observable<boolean> {
    if (UserRoles.hasRole(roles)) {
      if (this.hasSpecificModule()) {
        return this.userDataService.userDataCheck().pipe(switchMap((checked) => {
          if (checked) {
            this.userDataService.httpUpdate.next(false);
            if (!getLoginData().isImpersonate) {
              return this.userDataService.localSSrv.saveLocalStorageData(LocalStorageModuleTypes.TradeIn).pipe(
                switchMap(() => of(true)));
            }
            return of(true);
          }
          return of(false);
        }));
      }
      this.routerSrv.dashboardRoute();
      return of(false);
    }
    return this.goToLogin();
  }

  goToLogin(): Observable<boolean> {
    window.location.assign(EnvUrl.getEnvURL('Ecommerce'));
    return of(false);
  }

  removeData(): void {
    this.userDataService.dataRemover();
    window.location.assign(`${EnvUrl.getEnvURL('Ecommerce')}/login`);
  }

  hasSpecificModule(): boolean {
    if (this.userDataService.currentUrl.includes('/scanproducts') || this.userDataService.currentUrl.includes('/checkout')) {
      return !isAccountOwner() ? AppModules.hasItemLocationModule(ModuleTypes.TradeIn) : true;
    } else if (AppModules.hasModule(ModuleTypes.TradeIn)) {
      return true;
    } else if (AppModules.hasModule(ModuleTypes.Collection)) {
      window.location.assign(EnvUrl.getEnvURL('Collection'));
    } else {
      window.location.assign(EnvUrl.getEnvURL('Ecommerce'));
    }
  }

}
