import {HttpInterceptor} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {HttpInterceptorCommon} from '@common/ts/httpInterceptorCommon';
import {AuthService} from '@trade/services/auth/auth.service';
import {GlobalMessageService} from '@trade/services/global-message/global-message.service';

@Injectable()
export class HttpInterceptorService extends HttpInterceptorCommon<AuthService> implements HttpInterceptor {

  constructor(public override authService: AuthService,
              public override globalMsgSrv: GlobalMessageService) {
    super(authService, globalMsgSrv);
  }

}
